@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
body {
  margin: 0;
  font-family: sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mute {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
}
.muteInGame {
  font-size: 30px;
  cursor: pointer;
}
.feedback {
  padding: none;
  width: 80vw !important;
}
.logo {
  width: 70%;
  max-width: 103px;
  margin-top: 20px;
}
.logo-main {
  width: 103px;
  margin-top: 20px;
  margin-left: 20px;
}
.ant-comment-inner {
  padding: 2px 0 !important;
  border-bottom: 1px solid rgba(106, 233, 178, 0.2);
}
.message-container {
  position: relative;
  border-radius: 8px;
  padding: 10px;
  background-color: #ffffff;
  max-height: 90vh;
}
.messagesScroller::-webkit-scrollbar {
  display: none;
}
.messagesScroller {
  height: 100%;
  max-height: 55vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.guessForm {
  position: absolute;
  bottom: 0px;
}
.chat-drawer .ant-drawer-mask {
  background-color: transparent;
}
.chat-drawer .ant-drawer-content {
  background-color: rgba(255, 255, 255, 0.3);
}
.nickname {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media only screen and (max-width: 575px) {
  .mobile-messagesScroller {
    overflow-y: scroll;
    max-height: 70vh;
    margin-top: 120px;
  }
  .feedback {
    width: 95vw !important;
  }
  .guessForm {
    bottom: auto;
    top: 50px;
  }
  .ant-notification-notice {
    width: 300px !important;
  }
}

